<template>
  <div>
    <b-row>
      <b-col md="7" v-if="currentUser.level_akses == 1">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin.filter" @click="setting_coin.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins"
              :fields="setting_coin.fields"
              :filter="setting_coin.filter"
              :filter-included-fields="setting_coin.filterOn"
              :current-page="setting_coin.currentPage"
              :per-page="setting_coin.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin.currentPage"
                  :total-rows="listCoins == null ? 0 : listCoins.length"
                  :per-page="setting_coin.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin ERC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_erc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_erc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_erc.filter" @click="setting_coin_erc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_erc"
              :fields="setting_coin_erc.fields"
              :filter="setting_coin_erc.filter"
              :filter-included-fields="setting_coin_erc.filterOn"
              :current-page="setting_coin_erc.currentPage"
              :per-page="setting_coin_erc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_erc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_erc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_erc.currentPage"
                  :total-rows="listCoins_erc == null ? 0 : listCoins_erc.length"
                  :per-page="setting_coin_erc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin Hotbit BSC</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_hotbit_bsc(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_hotbit_bsc.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_hotbit_bsc.filter" @click="setting_coin_hotbit_bsc.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_hotbit_bsc"
              :fields="setting_coin_hotbit_bsc.fields"
              :filter="setting_coin_hotbit_bsc.filter"
              :filter-included-fields="setting_coin_hotbit_bsc.filterOn"
              :current-page="setting_coin_hotbit_bsc.currentPage"
              :per-page="setting_coin_hotbit_bsc.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_hotbit_bsc(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_hotbit_bsc(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_hotbit_bsc.currentPage"
                  :total-rows="listCoins_hotbit_bsc == null ? 0 : listCoins_hotbit_bsc.length"
                  :per-page="setting_coin_hotbit_bsc.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting Coin HECO</h3>
            </div>
            <div class="card-toolbar">
              <button :disabled="!is_admin" @click="formModalCoin_heco(null)" class="btn btn-primary"><b-icon-plus-circle /> Add</button>
            </div>
          </div>
          <div v-if="!is_admin" class="card-body">Admin Only</div>
          <div v-else class="card-body">
            <b-form-group label="Pencarian" label-for="filter-input" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-input-group size="sm">
                <b-form-input id="filter-input" v-model="setting_coin_heco.filter" type="search" placeholder="Ketikkan untuk pencarian"></b-form-input>
                <b-input-group-append>
                  <b-button :disabled="!setting_coin_heco.filter" @click="setting_coin_heco.filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
            <!-- table filter -->
            <b-table
              striped
              hover
              :items="listCoins_heco"
              :fields="setting_coin_heco.fields"
              :filter="setting_coin_heco.filter"
              :filter-included-fields="setting_coin_heco.filterOn"
              :current-page="setting_coin_heco.currentPage"
              :per-page="setting_coin_heco.perPage"
            >
              <template #cell(action)="data">
                <div class="text-right">
                  <b-button-group v-if="currentUser.level_akses == 1">
                    <b-button size="sm" variant="outline-primary" @click="formModalCoin_heco(data.item.nama_coin)"> <b-icon-pencil /></b-button>
                    <b-button size="sm" variant="outline-primary" @click="hapusCoin_heco(data.item.nama_coin)"> <b-icon-trash /></b-button>
                  </b-button-group>
                </div>
              </template>
            </b-table>
          </div>
          <div v-if="is_admin" class="card-footer">
            <b-row>
              <b-col md="8"></b-col>
              <b-col md="4">
                <b-pagination
                  v-model="setting_coin_heco.currentPage"
                  :total-rows="listCoins_heco == null ? 0 : listCoins_heco.length"
                  :per-page="setting_coin_heco.perPage"
                  align="fill"
                  size="sm"
                  class="my-0"
                ></b-pagination>
              </b-col>
            </b-row>
          </div>
        </div>
      </b-col>
      <b-col :md="currentUser.level_akses == 1 ? 5 : 12">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Setting App</h3>
            </div>
          </div>
          <div class="card-body">
            <b-form-group v-if="is_admin" label="Link BSD" label-for="setting_url_coin" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_url_coin" v-model="setting_url_coin" type="text" placeholder="Url"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="Link ERC" label-for="setting_url_coin_erc" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_url_coin_erc" v-model="setting_url_coin_erc" type="text" placeholder="Url"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="Link HECO" label-for="setting_url_coin_heco" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_url_coin_heco" v-model="setting_url_coin_heco" type="text" placeholder="Url"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="BUSD Address (BSC)" label-for="setting_busd_address" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_busd_address" v-model="setting_busd_address" type="text" placeholder="Token address BUSD"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="USDT Address (ERC)" label-for="setting_usdt_address" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_usdt_address" v-model="setting_usdt_address" type="text" placeholder="Token address USDT"></b-form-input>
            </b-form-group>
            <b-form-group v-if="is_admin" label="HUSD Address (HECO)" label-for="setting_husd_address" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input :disabled="!is_admin" id="setting_husd_address" v-model="setting_husd_address" type="text" placeholder="Token address HUSD"></b-form-input>
            </b-form-group>
            <b-form-group label="Timeout Request" label-for="timeout_request" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-input id="timeout_request" v-model="setting_timeout_request" type="number" placeholder="Timeout Request"></b-form-input>
            </b-form-group>
          </div>
        </div>

        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Import / Export Setting</h3>
            </div>
          </div>
          <div class="card-body">
            <h4>Import</h4>
            <b-form-group label="Import" label-for="setting_import" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-form-file id="setting_import" placeholder="Url" @change="onFileChange"></b-form-file>
            </b-form-group>
            <b-form-group label="" label-for="setting_import_btn" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-button @click="importSetting()" variant="primary" id="setting_import_btn"><b-icon-download /> Import</b-button>
            </b-form-group>
            <h4 v-if="is_admin">Export</h4>
            <b-form-group v-if="is_admin" label="Export" label-for="setting_export_btn" label-cols-sm="2" label-align-sm="right" label-size="sm" class="mb-3">
              <b-button :disabled="!is_admin" @click="exportSetting()" variant="primary" id="setting_export_btn"><b-icon-upload /> Export</b-button>
              <b-button class="ml-2" :disabled="!is_admin" @click="exportSettingRaw()" variant="primary" id="setting_export_btn"><b-icon-upload /> Export (Tanpa enkripsi)</b-button>
            </b-form-group>
          </div>
        </div>
      </b-col>
    </b-row>
    <!-- modals -->
    <b-modal ref="modal-form-coin" v-bind:title="form_coin.edit == false ? 'Edit Coin' : 'Add Coin'" @ok.prevent="simpanCoin()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-erc" v-bind:title="form_coin_erc.edit == false ? 'Edit Coin ERC' : 'Add Coin ERC'" @ok.prevent="simpanCoin_erc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_erc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_erc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_erc.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-hotbit_bsc" v-bind:title="form_coin_hotbit_bsc.edit == false ? 'Edit Coin HOTBIT BSC' : 'Add Coin HOTBIT BSC'" @ok.prevent="simpanCoin_hotbit_bsc()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_hotbit_bsc.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_hotbit_bsc.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_hotbit_bsc.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal ref="modal-form-coin-heco" v-bind:title="form_coin_heco.edit == false ? 'Edit Coin ERC' : 'Add Coin ERC'" @ok.prevent="simpanCoin_heco()">
      <b-form>
        <b-form-group id="ig_nama_coin" label="Nama Coin:" label-for="input_nama_coin">
          <b-form-input id="input_nama_coin" type="text" placeholder="Nama Coin" v-model="form_coin_heco.nama_coin" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_coin_address" label="Coin Address:" label-for="input_coin_address">
          <b-form-input id="input_coin_address" type="text" placeholder="Coin Address" v-model="form_coin_heco.coin_address" required></b-form-input>
        </b-form-group>
        <b-form-group id="ig_desimal" label="Desimal:" label-for="input_desimal">
          <b-form-input id="input_desimal" type="number" placeholder="Desimal" v-model="form_coin_heco.desimal" required></b-form-input>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
import download from "downloadjs";
import CryptoService from "@/core/services/crypto.service";

export default {
  data() {
    return {
      setting_coin: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_erc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_hotbit_bsc: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      setting_coin_heco: {
        totalRows: 1,
        currentPage: 1,
        perPage: 5,
        filter: null,
        filterOn: ["nama_coin", "coin_address", "desimal"],
        // Note 'isActive' is left out and will not appear in the rendered table
        fields: [
          {
            key: "nama_coin",
            label: "Nama Coin",
            sortable: true,
          },
          {
            key: "coin_address",
            label: "Coin Address",
            sortable: true,
          },
          {
            key: "desimal",
            label: "Desimal",
            sortable: true,
          },
          {
            key: "action",
            label: "",
            sortable: false,
          },
        ],
        items: [],
      },
      form_coin: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_erc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_hotbit_bsc: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      form_coin_heco: {
        edit: false,
        oriinal: null,
        nama_coin: null,
        coin_address: null,
        desimal: null,
      },
      imported_setting: null,
      is_admin: true,
    };
  },
  computed: {
    ...mapGetters(["listCoins", "listCoins_erc", "listCoins_heco", "listCoins_hotbit_bsc", "listCoins_matcha_erc", "currentUser"]),
    setting_url_coin: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin", value: newValue });
      },
    },
    setting_url_coin_erc: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_erc || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_erc", value: newValue });
      },
    },
    setting_url_coin_hotbit_bsc: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_hotbit_bsc || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_hotbit_bsc", value: newValue });
      },
    },
    setting_url_coin_heco: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.url_coin_heco || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "url_coin_heco", value: newValue });
      },
    },
    setting_busd_address: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.busd_address || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "busd_address", value: newValue });
      },
    },
    setting_usdt_address: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.usdt_address || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "usdt_address", value: newValue });
      },
    },
    setting_husd_address: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.husd_address || null;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "husd_address", value: newValue });
      },
    },
    setting_timeout_request: {
      get: function() {
        return this.$store.state.setting.settings == null ? null : this.$store.state.setting.settings.timeout_request || 15;
      },
      set: function(newValue) {
        this.$store.dispatch("settingSet", { name: "timeout_request", value: newValue });
      },
    },
  },
  components: {},
  methods: {
    onFileChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.readFile(files[0]);
    },
    readFile(file) {
      let reader = new FileReader();
      reader.onload = e => {
        try {
          this.imported_setting = JSON.parse(CryptoService.decrypt(e.target.result));
          console.log(this.imported_setting);
        } catch (er1) {
          try {
            this.imported_setting = JSON.parse(e.target.result);
          } catch (er2) {
            this.imported_setting = null;
          }
        }
      };
      reader.readAsText(file);
    },
    importSetting() {
      if (this.imported_setting == null) {
        Swal.fire({
          title: "File kosong / Tidak valid!",
          text: "File json untuk import kosong atau format json tidak valid",
          icon: "error",
          heightAuto: false,
        });
      } else {
        let jsonimport = this.imported_setting;
        if (jsonimport.coin == undefined || jsonimport.coin_erc == undefined || jsonimport.coin_heco == undefined || jsonimport.settings == undefined) {
          return this.swalFormatSalah("coin dan settings tidak ditemukan");
        }
        let err = 0;
        jsonimport.coin.forEach(item => {
          if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
            err++;
            console.log("coin", item);
          }
        });
        jsonimport.coin_erc.forEach(item => {
          if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
            err++;
            console.log("coin_erc", item);
          }
        });
        jsonimport.coin_hotbit_bsc.forEach(item => {
          if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
            err++;
            console.log("coin_hotbit_bsc", item);
          }
        });
        jsonimport.coin_heco.forEach(item => {
          if (item.nama_coin == undefined || item.coin_address == undefined || item.desimal == undefined) {
            err++;
            console.log("coin_heco", item);
          }
        });
        if (err > 0) {
          return this.swalFormatSalah("json coin salah");
        }
        if (jsonimport.settings.url_coin == undefined || jsonimport.settings.busd_address == undefined) {
          return this.swalFormatSalah("json setting salah");
        }
        this.$store.dispatch("setSetting", jsonimport.settings).then(() => {
          this.$store
            .dispatch("importCoin", {
              coin: jsonimport.coin,
              coin_erc: jsonimport.coin_erc,
              coin_hotbit_bsc: jsonimport.coin_hotbit_bsc,
              coin_heco: jsonimport.coin_heco,
            })
            .then(() => {
              Swal.fire({
                title: "Import sukses!",
                text: "Import setting berhasil",
                icon: "success",
                heightAuto: false,
              });
            });
        });
      }
    },
    swalFormatSalah(msg) {
      Swal.fire({
        title: "Format salah!",
        text: "Format json salah. " + msg,
        icon: "error",
        heightAuto: false,
      });
      return false;
    },
    exportSetting() {
      let payload = {
        settings: this.$store.state.setting.settings,
        coin: this.listCoins,
        coin_erc: this.listCoins_erc,
        coin_heco: this.listCoins_heco,
        coin_hotbit_bsc: this.listCoins_hotbit_bsc,
      };
      download(CryptoService.encrypt(JSON.stringify(payload)), "wm_setting.json", "text/plain");
    },
    exportSettingRaw() {
      let payload = {
        settings: this.$store.state.setting.settings,
        coin: this.listCoins,
        coin_erc: this.listCoins_erc,
        coin_heco: this.listCoins_heco,
        coin_hotbit_bsc: this.listCoins_hotbit_bsc,
      };
      download(JSON.stringify(payload), "wm_setting_raw.json", "text/plain");
    },
    formModalCoin(nama_coin) {
      if (nama_coin == null) {
        this.form_coin.edit = false;
        this.form_coin.original = null;
        this.form_coin.nama_coin = "";
        this.form_coin.coin_address = "";
        this.form_coin.desimal = "";
      } else {
        let coin = this.listCoins.find(item => item.nama_coin == nama_coin);
        this.form_coin.edit = true;
        this.form_coin.original = coin.nama_coin;
        this.form_coin.nama_coin = coin.nama_coin;
        this.form_coin.coin_address = coin.coin_address;
        this.form_coin.desimal = coin.desimal;
      }
      this.$refs["modal-form-coin"].show();
    },
    formModalCoin_erc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_erc.edit = false;
        this.form_coin_erc.original = null;
        this.form_coin_erc.nama_coin = "";
        this.form_coin_erc.coin_address = "";
        this.form_coin_erc.desimal = "";
      } else {
        let coin_erc = this.listCoins_erc.find(item => item.nama_coin == nama_coin);
        this.form_coin_erc.edit = true;
        this.form_coin_erc.original = coin_erc.nama_coin;
        this.form_coin_erc.nama_coin = coin_erc.nama_coin;
        this.form_coin_erc.coin_address = coin_erc.coin_address;
        this.form_coin_erc.desimal = coin_erc.desimal;
      }
      this.$refs["modal-form-coin-erc"].show();
    },
    formModalCoin_hotbit_bsc(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_hotbit_bsc.edit = false;
        this.form_coin_hotbit_bsc.original = null;
        this.form_coin_hotbit_bsc.nama_coin = "";
        this.form_coin_hotbit_bsc.coin_address = "";
        this.form_coin_hotbit_bsc.desimal = "";
      } else {
        let coin_hotbit_bsc = this.listCoins_hotbit_bsc.find(item => item.nama_coin == nama_coin);
        this.form_coin_hotbit_bsc.edit = true;
        this.form_coin_hotbit_bsc.original = coin_hotbit_bsc.nama_coin;
        this.form_coin_hotbit_bsc.nama_coin = coin_hotbit_bsc.nama_coin;
        this.form_coin_hotbit_bsc.coin_address = coin_hotbit_bsc.coin_address;
        this.form_coin_hotbit_bsc.desimal = coin_hotbit_bsc.desimal;
      }
      this.$refs["modal-form-coin-hotbit_bsc"].show();
    },
    formModalCoin_heco(nama_coin) {
      if (nama_coin == null) {
        this.form_coin_heco.edit = false;
        this.form_coin_heco.original = null;
        this.form_coin_heco.nama_coin = "";
        this.form_coin_heco.coin_address = "";
        this.form_coin_heco.desimal = "";
      } else {
        let coin_heco = this.listCoins_heco.find(item => item.nama_coin == nama_coin);
        this.form_coin_heco.edit = true;
        this.form_coin_heco.original = coin_heco.nama_coin;
        this.form_coin_heco.nama_coin = coin_heco.nama_coin;
        this.form_coin_heco.coin_address = coin_heco.coin_address;
        this.form_coin_heco.desimal = coin_heco.desimal;
      }
      this.$refs["modal-form-coin-heco"].show();
    },
    simpanCoin() {
      if (this.form_coin.nama_coin == "" || this.form_coin.coin_address == "" || this.form_coin.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin", this.form_coin).then(() => {
        this.$refs["modal-form-coin"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_erc() {
      if (this.form_coin_erc.nama_coin == "" || this.form_coin_erc.coin_address == "" || this.form_coin_erc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_erc", this.form_coin_erc).then(() => {
        this.$refs["modal-form-coin-erc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_hotbit_bsc() {
      if (this.form_coin_hotbit_bsc.nama_coin == "" || this.form_coin_hotbit_bsc.coin_address == "" || this.form_coin_hotbit_bsc.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_hotbit_bsc", this.form_coin_hotbit_bsc).then(() => {
        this.$refs["modal-form-coin-hotbit_bsc"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    simpanCoin_heco() {
      if (this.form_coin_heco.nama_coin == "" || this.form_coin_heco.coin_address == "" || this.form_coin_heco.desimal == "") {
        Swal.fire({
          title: "Salah!",
          text: "Data tidak lengkap",
          icon: "error",
          heightAuto: false,
        });
        return false;
      }
      this.$store.dispatch("saveCoin_heco", this.form_coin_heco).then(() => {
        this.$refs["modal-form-coin-heco"].hide();
        Swal.fire({
          title: "Berhasil!",
          text: "Coin berhasil disimpan",
          icon: "success",
          heightAuto: false,
        });
      });
    },
    hapusCoin(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_erc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_erc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_hotbit_bsc(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_hotbit_bsc", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
    hapusCoin_heco(nama_coin) {
      Swal.fire({
        title: "Apakah anda yakin?",
        text: "Data " + nama_coin + " akan dihapus permanen",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then(result => {
        if (result.isConfirmed) {
          this.$store.dispatch("deleteCoin_heco", nama_coin).then(() => {
            Swal.fire({
              title: "Berhasil!",
              text: "Coin berhasil dihapus",
              icon: "success",
              heightAuto: false,
            });
          });
        }
      });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Market Watch" }, { title: "Setting" }]);
    this.is_admin = this.currentUser.level_akses == 1;
  },
};
</script>
